export const erc20: any[] = [{
  "constant": true,
  "inputs": [],
  "name": "name",
  "outputs": [{
      "name": "",
      "type": "string"
  }],
  "type": "function"
},
{
  "constant": false,
  "inputs": [{
          "name": "_from",
          "type": "address"
      },
      {
          "name": "_to",
          "type": "address"
      },
      {
          "name": "_value",
          "type": "uint256"
      }
  ],
  "name": "transferFrom",
  "outputs": [{
      "name": "success",
      "type": "bool"
  }],
  "type": "function"
},
{
  "constant": true,
  "inputs": [],
  "name": "decimals",
  "outputs": [{
      "name": "",
      "type": "uint8"
  }],
  "type": "function"
},
{
  "constant": true,
  "inputs": [{
      "name": "",
      "type": "address"
  }],
  "name": "balanceOf",
  "outputs": [{
      "name": "",
      "type": "uint256"
  }],
  "type": "function"
},
{
  "constant": true,
  "inputs": [],
  "name": "symbol",
  "outputs": [{
      "name": "",
      "type": "string"
  }],
  "type": "function"
},
{
  "constant": false,
  "inputs": [{
          "name": "_to",
          "type": "address"
      },
      {
          "name": "_value",
          "type": "uint256"
      }
  ],
  "name": "transfer",
  "outputs": [],
  "type": "function"
},
{
  "constant": false,
  "inputs": [{
          "name": "_spender",
          "type": "address"
      },
      {
          "name": "_value",
          "type": "uint256"
      },
      {
          "name": "_extraData",
          "type": "bytes"
      }
  ],
  "name": "approveAndCall",
  "outputs": [{
      "name": "success",
      "type": "bool"
  }],
  "type": "function"
},
{
  "constant": true,
  "inputs": [{
          "name": "",
          "type": "address"
      },
      {
          "name": "",
          "type": "address"
      }
  ],
  "name": "spentAllowance",
  "outputs": [{
      "name": "",
      "type": "uint256"
  }],
  "type": "function"
},
{
  "constant": true,
  "inputs": [{
          "name": "",
          "type": "address"
      },
      {
          "name": "",
          "type": "address"
      }
  ],
  "name": "allowance",
  "outputs": [{
      "name": "",
      "type": "uint256"
  }],
  "type": "function"
},
{
  "inputs": [{
          "name": "initialSupply",
          "type": "uint256"
      },
      {
          "name": "tokenName",
          "type": "string"
      },
      {
          "name": "decimalUnits",
          "type": "uint8"
      },
      {
          "name": "tokenSymbol",
          "type": "string"
      }
  ],
  "type": "constructor"
},
{
  "anonymous": false,
  "inputs": [{
          "indexed": true,
          "name": "from",
          "type": "address"
      },
      {
          "indexed": true,
          "name": "to",
          "type": "address"
      },
      {
          "indexed": false,
          "name": "value",
          "type": "uint256"
      }
  ],
  "name": "Transfer",
  "type": "event"
}
]