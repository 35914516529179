import { Button, Checkbox } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowRightImg from "../../assets/images/svg/arrow-right.svg";
import ShareImg from "../../assets/images/svg/share.svg";
import "./index.css";
function TermsOfUse() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  return (
    <div className="terms-of-use">
      <div className="title">{t('termsOfUse.title')}</div>
      <div className="terms-item">
        {t('termsOfUse.termsOfService')}
        <img src={ShareImg} alt=""></img>
      </div>
      <div className="terms-item">
        {t('termsOfUse.privacyPolicy')}
        <img src={ShareImg} alt=""></img>
      </div>
      <Checkbox defaultChecked>{t('termsOfUse.tip')}</Checkbox>
      <Button block color="primary" size="large" onClick={() => { navigate("/select-device")} }>
        {t('termsOfUse.start')}
        <img src={ArrowRightImg} alt=""></img>
      </Button>
    </div>
  );
}
export default TermsOfUse;
