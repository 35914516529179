import { networks, payments, bip32 } from "@shapeshiftoss/bitcoinjs-lib";
import { BncClient } from "@binance-chain/javascript-sdk";
import * as bip39 from "bip39";
import { fromHex } from "tron-format-address";
import { hdkey } from "ethereumjs-wallet";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function initWallet(mnemonic: string) {
  const network = networks.bitcoin;
  const seed = await bip39.mnemonicToSeed(mnemonic);
  const root = bip32.fromSeed(seed, network);
  const path = "m/44'/0'/0'/0/0";
  const keyPair = root.derivePath(path);
  const privateKey = keyPair.toWIF(); // BTC私钥
  // const publicKey = keyPair.publicKey.toString("hex"); // BTC公钥
  const address = payments.p2pkh({
    pubkey: keyPair.publicKey,
    network: network,
  }).address; // BTC地址
  const hdWallet = hdkey.fromMasterSeed(seed);
  const ethKey = hdWallet.derivePath("m/44'/60'/0'/0/0");
  // bnb  https://dex-asiapacific.binance.org/  explorer explorer
  const client = new BncClient("https://explorer.binance.org/"); //https://api.binance.com/
  client.chooseNetwork("mainnet");
  const bnb = client.recoverAccountFromMneomnic(mnemonic);
  // trx
  const hdWalletTrx = hdkey.fromMasterSeed(seed);
  const keyTrx = hdWalletTrx.derivePath("m/44'/195'/0'/0/0");
  return {
    btcAddress: address,
    ethAddress: ethKey.getWallet().getAddressString(),
    bnbAddress: bnb.address,
    trxAddress: fromHex(keyTrx.getWallet().getAddressString()),
    btcPrivateKey: privateKey,
    ethPrivateKey: ethKey.getWallet().getPrivateKeyString(),
    bnbPrivateKey: bnb.privateKey,
    trxPrivateKey: keyTrx.getWallet().getPrivateKeyString(),
    mnemonic: mnemonic,
  };
}
