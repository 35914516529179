import "./App.css";
import BasicRoute from "./routes";

function App() {
  return (
    <div className="App">
      <BasicRoute></BasicRoute>
    </div>
  );
}

export default App;
