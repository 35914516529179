import axios from "axios";
import BigNumber from "bignumber.js";

export async function BtcUSDTBalance(address: string) {
  let balance = '0.00'
  const omniResult = await axios({
    method: 'post',
    url: 'https://api.omniexplorer.info/v2/address/addr',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: address
  })
  if (omniResult.status === 200) {
    if (omniResult.data && omniResult.data.length > 0) {
      const SP31Balance = omniResult.data[0].balance.find((oel: { symbol: string; }) => oel.symbol === 'SP31')
      if (SP31Balance) {
        balance = new BigNumber(SP31Balance.value).times(0.00000001).toFixed(2, 2)
      }
    }
  }
  return balance
}
