import "./index.css";
// @ts-ignore
import TronWeb from "tronweb";
import MenuIcon from "../../assets/images/svg/menu.svg";
import BalanceIcon from "../../assets/images/svg/balance.svg";
import BtcIcon from "../../assets/images/png/icon_btc.png";
import BnbIcon from "../../assets/images/png/icon_bnb.png";
import TrxIcon from "../../assets/images/png/icon_trx.png";
import EthIcon from "../../assets/images/png/icon_eth.png";
import USDTIcon from "../../assets/images/svg/USDT.svg";
import AccountIcon from "../../assets/images/svg/account.svg";
import PortfolioIcon from "../../assets/images/svg/portfolio.svg";
import TransferIcon from "../../assets/images/svg/transfer.svg";
import DiscoverIcon from "../../assets/images/svg/discover.svg";
import ManagerIcon from "../../assets/images/svg/manager.svg";
import { Popup } from "antd-mobile";
import SelectCurrency from "../SelectCurrency";
import { useCallback, useEffect, useState } from "react";
import AccountOperation from "./AccountOperation";
import SignOut from "./SignOut";
import { LocalWalletModel } from "../../Data/Wallet";
import { decryptByDES } from "../../utils/Des";
import axios from "axios";
import { toBtc } from "../../utils/Utils";
import Web3 from "web3";
import { BncClient } from "@binance-chain/javascript-sdk";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BtcUSDTBalance } from "../../utils/BtcUSDT";
import { EthUSDTBalance } from "../../utils/EthUSDT";
import { TrxUSDTBalance } from "../../utils/TrxUSDT";

// function signOut(){
// 	const navigate = useNavigate();
// 	localStorage.removeItem("wallet");
// 	navigate("/");
// }

function Account() {
  const navigate = useNavigate();
  const [wallet, setWallet] = useState<any>(null);
  const [btcBalance, setBtcBalance] = useState("0");
  const [btcUSDTBalance, setBtcUSDTBalance] = useState("0.00");
  const [ethBalance, setEthBalance] = useState("0");
  const [ethUSDTBalance, setEthUSDTBalance] = useState("0.00");
  const [trxBalance, setTrxBalance] = useState("0");
  const [trxUSDTBalance, setTrxUSDTBalance] = useState("0.00");
  const [bnbBalance, setBnbBalance] = useState("0");
  const [bnbUSDTBalance, setBnbUSDTBalance] = useState("0.00");
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState("0");
  const [popup, setPopup] = useState(false);
  const [operationPopup, setOperationPopup] = useState(false);
  const [currency, setCurrency] = useState("btc");
  const { t } = useTranslation()




  useEffect(() => {
    const walletStr = localStorage.getItem("wallet");
    if (walletStr) {
      const walletArr: Array<LocalWalletModel> = JSON.parse(walletStr);
      const wallet1 = walletArr.find((el) => el.isDefault === 1);

	  
      if (wallet1) {
        const walletDes = decryptByDES(
          wallet1.wallet,
          sessionStorage.getItem("password") || ""
        );
        if (walletDes) {

          setWallet(JSON.parse(walletDes));
          setAddress(JSON.parse(walletDes).btcAddress);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (wallet) {
      getAllBalance();
    }
  }, [wallet])

  // 获取余额
  const getAllBalance = useCallback(async () => {
    if (wallet) {
      // btc 余额
      axios(
        `https://api.blockcypher.com/v1/btc/main/addrs/${wallet.btcAddress}`
      ).then((res) => {
        setBtcBalance(toBtc(res.data.balance, 0.00000001));
        setBalance(btcBalance)
      });
      setBtcUSDTBalance(await BtcUSDTBalance(wallet.btcAddress))
      // eth余额
      const web3 = new Web3(
        new Web3.providers.HttpProvider(
          "https://mainnet.infura.io/v3/c9069e41f4ca4830bc7229c376099150"
        )
      );

      setEthUSDTBalance(await EthUSDTBalance(wallet.ethAddress))
      web3.eth.getBalance(wallet.ethAddress).then((res) => {
        setEthBalance(web3.utils.fromWei(res));
      });
      // bnb
      const api = "https://dex.binance.org/"; /// api string or dex.binance.org testnet-dex.binance.org
      const bnbClient = new BncClient(api);

      bnbClient.chooseNetwork("mainnet"); // or this can be "testnet"
      bnbClient.initChain();
      bnbClient.getBalance(wallet.bnbAddress).then((res) => {
        if (res && res.length > 0) {
          setBnbBalance(res[0].free);
        }
      });
      // tron
      const tronWeb = new TronWeb({
        fullHost: "https://api.shasta.trongrid.io/",
        headers: {
          "TRON-PRO-API-KEY": "5a0bc822-666a-47a0-afe9-0dc71dee3c08",
        },
      });
      setTrxUSDTBalance(await TrxUSDTBalance(wallet?.trxAddress))
      tronWeb.trx.getBalance(wallet?.trxAddress).then((res: any) => {
        setTrxBalance(tronWeb.fromSun(res));
      });
    }
  }, [btcBalance, wallet]);

  return (
    <div className="account">
      <div className="header flex-center">
        <img alt="" src={MenuIcon} onClick={() => setPopup(true)}></img>
        <span>{t('account.account')}</span>
        <SignOut signOut={(type: string) => {
			localStorage.removeItem("wallet");
			navigate("/")
		}}></SignOut>
      </div>
      <div className="content">
        <div className="balance flex-center">
          <div className="left">
            <img src={BalanceIcon} alt="" />
            <span>{t('account.balance')}</span>
          </div>
          <div className="right">$0.06</div>
        </div>
        <div className="currency flex-center">
          <div className="left">
            <img
              width={28}
              src={
                currency === "btc"
                  ? BtcIcon
                  : currency === "eth"
                  ? EthIcon
                  : currency === "bnb"
                  ? BnbIcon
                  : TrxIcon
              }
              alt=""
            />
            <span>{currency}</span>
          </div>
          <div className="right">
            <div className="currency-balance">
              {balance}{" "}
              {currency}
            </div>
            <div className="u-balance">$0.06</div>
          </div>
        </div>
        <div className="currency flex-center">
          <div className="left">
            <img
              width={28}
              src={USDTIcon}
              alt=""
            />
            <span>{currency}</span>
          </div>
          <div className="right">
            <div className="currency-balance">
              {
                currency === "btc"
                ? btcUSDTBalance
                : currency === "eth"
                ? ethUSDTBalance
                : currency === "bnb"
                ? bnbUSDTBalance
                : trxUSDTBalance
              } USDT
            </div>
            <div className="u-balance">$0.06</div>
          </div>
        </div>
      </div>
      <Popup
        visible={popup}
        onMaskClick={() => {
          setPopup(false);
        }}
        position="left"
        bodyStyle={{ width: "80vw" }}
      >
        <SelectCurrency
          changeCurrency={(e: string) => {
            if (e === "btc") {
              setAddress(wallet.btcAddress);
              setBalance(btcBalance)
            } else if (e === "eth") {
              setAddress(wallet.ethAddress);
              setBalance(ethBalance)
            } else if (e === "bnb") {
              setAddress(wallet.bnbAddress);
              setBalance(bnbBalance)
            } else if (e === "trx") {
              setAddress(wallet.trxAddress);
              setBalance(trxBalance)
            }
            setCurrency(e);
            setPopup(false);
          }}
        ></SelectCurrency>
      </Popup>
      <Popup
        visible={operationPopup}
        onMaskClick={() => {
          setOperationPopup(false);
        }}
        position="bottom"
      >
        <AccountOperation handleClose={(type: string) => {
          if (type === 'send') {
            navigate("/send", {
              state: {
                balance: balance,
                address: address,
                currency: currency
              },
            });
          } else if (type === 'receive')  {
            navigate("/receive", {
              state: {
                address: address,
                currency: currency
              },
            });
          }
          setOperationPopup(false)
        }}></AccountOperation>
      </Popup>
      <div className="footer-menu">
        <div className="footer-item">
          <img alt="" src={PortfolioIcon} />
          {t('account.assets')}
        </div>
        <div className="footer-item active">
          <img alt="" src={AccountIcon} />
          {t('account.account')}
        </div>
        <img
          alt=""
          src={TransferIcon}
          onClick={() => setOperationPopup(true)}
        />
        <div className="footer-item">
          <img alt="" src={DiscoverIcon} />
          {t('account.manager')}
        </div>
        <div className="footer-item">
          <img alt="" src={ManagerIcon} />
          {t('account.discover')}
        </div>
      </div>
    </div>
  );
}
export default Account;
