import { Button, Input, Modal } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocalWalletModel } from "../../Data/Wallet";
import { decryptByDES } from "../../utils/Des";
import "./index.css";
function EnterPin({ visible }: { visible: boolean }) {
  const [value, setValue] = useState("");
  const [show, setShow] = useState(visible);
  const [tip, setTip] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const unlock = () => {
    const walletStr = localStorage.getItem("wallet");
    if (walletStr) {
      const walletArr: Array<LocalWalletModel> = JSON.parse(walletStr);
      const wallet1 = walletArr.find((el) => el.isDefault === 1);
      if (wallet1) {
        const walletDes = decryptByDES(
          wallet1.wallet,
          value
        );
        if (walletDes !== 'error') {
			sessionStorage.setItem("password", value);
			
          setShow(false)
          navigate('/account')
        } else {
          setTip('密码不匹配')
        }
      }
    }
  }

  return (
    <div className="enter-pin">
      <Modal
        visible={show}
        content={
          <div>
            <div className="modal-title">{t('home.inputPin')}</div>
            <Input
              className="modal-input"
              value={value}
              onChange={(val) => {
                setValue(val);
              }}
            />
            {tip && <div className="modal-tip">{tip}</div>}
            <Button color="primary" className="modal-button" disabled={!value} onClick={() => {unlock()}}>{t('common.confirm')}</Button>
          </div>
        }
        closeOnAction
        // onClose={() => {
        //   setVisible(false)
        // }}
      />
    </div>
  );
}
export default EnterPin;
