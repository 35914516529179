import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BgImg from "../../assets/images/png/start-wallet.png";
import "./index.css";
function StartWallet() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  return (
    <div className="start-wallet">
      <div className="title">{t('startWallet.title')}</div>
      <div className="sub-title">{t('startWallet.subTitle')}</div>
      <div
        className="item"
        onClick={() => {
          navigate("/set-pin", {
            state: {
              type: 0,
            },
          });
        }}
      >
        <div className="label">{t('startWallet.create')}</div>
        <div className="info">{t('startWallet.first')}</div>
      </div>
      <div
        className="item"
        onClick={() => {
          navigate("/set-pin", {
            state: {
              type: 1,
            },
          });
        }}
      >
        <div className="label">{t('startWallet.recover')}</div>
        <div className="info">{t('startWallet.have')}</div>
      </div>
      <img src={BgImg} alt=""></img>
    </div>
  );
}
export default StartWallet;
