//DES加密 or RAS加密
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt"


export function decryptByDES(ciphertext: string, key: string): string {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const decrypted = CryptoJS.DES.decrypt(ciphertext,
    keyHex,
    {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  try {
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e: any) {
    return 'error';
  }
}

export function encryptByDES(content: string, key: string): string {
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.DES.encrypt(content, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

export function mnemonicRAS(key: string): string {
	const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAghkqP3enteE0CTZgpA51vUY5PPCqVb+s01b75blFDD9fmb2fI70r08K3LyfmasBzEKpWitkdnl0rNTBfCD8XqG9YUATOA9hggfyvkVTnG6/MUlfgwq0FmpwKkVWDoWDUrAn9EEq3455ONA8IoWG5Eqa0KFIIILoviZGiFxkbRFDrQpuuHgkc4K5Io44ETiM3Q8QC/d/B6BgAfUP2oG6wSbh7Jk/pxBeLPs4FudFuhUl3d57coyiCkYmklsGqhxg21iHZECWa0c+iujlkMOIIXGUmSW+c7CVNcSPLLx01tH25KmcmfWcg3Y3c0Qga+m88QxGMiFqRp1XcoJOdmySysQIDAQAB';
	const encrypt = new JSEncrypt();
	encrypt.setPublicKey(publicKey);
	let password = encrypt.encrypt(key);// 加密后的字符串
	
	return password.toString();
}