import { Button } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BgImg from "../../assets/images/svg/set-device-bg.svg";
import "./index.css";
function SetDevice() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  return (
    <div className="set-device">
      <div className="title">{t('setDevice.title')}</div>
      <div className="sub-title">{t('setDevice.subTitle')}</div>
      <Button block color="primary" size="large" onClick={() => { navigate("/start-wallet")} }>
        {t('setDevice.started')}
      </Button>
      <img src={BgImg} alt=""></img>
    </div>
  );
}
export default SetDevice;
