import Header from "../../components/Header";
import "./index.css";
import { Button, Dialog, Form, Input, Switch } from "antd-mobile";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { transfer } from "../../utils/Transfer";
import { useTranslation } from "react-i18next";

function Send() {
  const location = useLocation();
  const [currency] = useState((location.state as any).currency);
  const [address, setAddress] = useState("");
  const [balance] = useState((location.state as any).balance);
  const [amount, setAmount] = useState("");
  const [allCheck, setAllCheck] = useState(false);
  const { t } = useTranslation()
  const onFinish = async () => {
    const result = await transfer(currency.toLocaleUpperCase(), address, amount);
    Dialog.alert({
      content: result,
      className: "send-dialog",
      onConfirm: () => {
        console.log('Confirmed')
      },
    })
  };
  return (
    <div className="send">
      <Header title={t('send.send')}></Header>
      <Form
        onFinish={onFinish}
        footer={
          <Button block type="submit" color="primary" size="large">
            {t('send.submit')}
          </Button>
        }
      >
        <Form.Item
          name="address"
          label={t('send.address')}
          help={t('send.address')}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={t('send.inputAddress')}
            value={address}
            onChange={(val) => {
              setAddress(val);
            }}
          />
        </Form.Item>
        <Form.Item name="amount" label={t('send.amount')} rules={[{ required: true }]}>
          <Input
            placeholder={t('send.amountInput')}
            readOnly={allCheck}
            value={amount}
            onChange={(val) => {
              setAmount(val);
            }}
          />
        </Form.Item>
      </Form>
      <div className="available">
        <div className="balance">
          <div className="label">{t('send.totalAmount')}</div>
          <div className="value">{balance}</div>
        </div>
        <div className="right">
        {t('send.max')}
          <Switch
            checked={allCheck}
            onChange={(checked) => {
              if (checked) {
                setAmount(balance)
              }
              setAllCheck(checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default Send;
