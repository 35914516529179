import { Button, Checkbox, Input } from "antd-mobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BgImg from "../../assets/images/svg/set-pin.svg";
import "./index.css";
function SetPin() {
  const [pin, setPin] = useState("");
  const [showTip, setShowTip] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation()
 
  return (
    <div className="set-pin">
      <img src={BgImg} alt="" className="set-pin-big-img"></img>
      <div className="content">
        <div className="title"> {t('setPin.title')}</div>
        <div className="sub-title">{t('setPin.subTitle')}</div>
          
        <Input
          type='password'
          placeholder={t('setPin.inputPassword')}
          value={pin}
          onChange={(val) => {
            setPin(val);
          }}
        />
        <Input
          type='password'
          placeholder={t('setPin.twoInputPassword')}
          value={confirmPin}
          onChange={(val) => {
            setConfirmPin(val);
          }}
        />
        <Checkbox
          checked={checked}
          onChange={(val) => {
            setChecked(val);
          }}
        >
           {t('setPin.tip')}
        </Checkbox>
        {showTip && <div className="tip"> {t('setPin.errorTip')}</div>}
        <Button
          block
          color="primary"
          size="large"
          disabled={!pin || !confirmPin || !checked}
          onClick={() => {
            if (pin === confirmPin) {
              navigate(location.state && (location.state as any).type === 0 ? "/remember-mnemonic" : '/recover-mnemonic', {
                state: {
                  pin: pin
                },
              });
            } else {
              setShowTip(true)
            }
          }}
        >
          {t('setPin.setPin')}
        </Button>
      </div>
    </div>
  );
}
export default SetPin;
