// @ts-ignore
import TronWeb from "tronweb";
import BigNumber from "bignumber.js";
const tronWeb = new TronWeb({
  fullHost: "https://api.trongrid.io/",
  headers: { "TRON-PRO-API-KEY": "d4051326-d9dc-4c2d-93e1-0e306e5dae88" },
});
let trxContract: any = null

export async function TrxUSDTBalance(address: string) {
  if (!trxContract) {
    trxContract = await tronWeb.contract().at("41a614f803b6fd780986a42c78ec9c7f77e6ded13c")
}
tronWeb.setAddress(address);
  let balance = '0.00'
  if (trxContract) {
    let erc20Balance = await trxContract.balanceOf(address).call()
    if (erc20Balance) {
      balance = new BigNumber(erc20Balance.toString()).times(0.000001).toFixed(2, 2)
    }
}
  return balance
}
