import { MemoryRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Detail from "../pages/Detail";
import TermsOfUse from "../pages/TermsOfUse";
import SelectDevice from "../pages/SelectDevice";
import SetDevice from "../pages/SetDevice";
import StartWallet from "../pages/StartWallet";
import SetPin from "../pages/SetPin";
import RememberMnemonic from "../pages/RememberMnemonic";
import RecoverMnemonic from "../pages/RecoverMnemonic";
import Account from "../pages/Account";
import Receive from "../pages/Receive";
import Send from "../pages/Send";

const BasicRoute = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/account" element={<Account />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/select-device" element={<SelectDevice />} />
      <Route path="/set-device" element={<SetDevice />} />
      <Route path="/set-pin" element={<SetPin />} />
      <Route path="/receive" element={<Receive />} />
      <Route path="/send" element={<Send />} />
      <Route path="/remember-mnemonic" element={<RememberMnemonic />} />
      <Route path="/recover-mnemonic" element={<RecoverMnemonic />} />
      <Route path="/start-wallet" element={<StartWallet />} />
      <Route path="/detail" element={<Detail />} />
    </Routes>
  </Router>
);

export default BasicRoute;
