import SendIcon from "../../assets/images/svg/send.svg";
import ReceiveIcon from "../../assets/images/svg/receive.svg";

function AccountOperation({handleClose}: {handleClose: Function}) {
  return (
    <div className="account-operation">
      <div className="item" onClick={() => {handleClose('send')}}>
        <img alt="" src={SendIcon} />
        发送
      </div>
      <div className="item" onClick={() => {handleClose('receive')}}>
        <img alt="" src={ReceiveIcon} />
        接收
      </div>
    </div>
  );
}
export default AccountOperation;
