import QRCode from "qrcode.react";
import Header from "../../components/Header";
import BtcIcon from "../../assets/images/png/icon_btc.png";
import BnbIcon from "../../assets/images/png/icon_bnb.png";
import TrxIcon from "../../assets/images/png/icon_trx.png";
import EthIcon from "../../assets/images/png/icon_eth.png";
import CopyIcon from "../../assets/images/svg/copy.svg";
import "./index.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { Toast } from "antd-mobile";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function Receive() {
  const location = useLocation();
  const [currency] = useState((location.state as any).currency);
  const [address] = useState((location.state as any).address);
  const { t } = useTranslation()

  return (
    <div className="receive">
      <Header title={t('receive.header')}></Header>
      <QRCode value="http://facebook.github.io/react/" />
      <div className="label">{t('receive.address')}</div>
      <div className="currency">
        <img
          src={
            currency === "btc"
              ? BtcIcon
              : currency === "eth"
              ? EthIcon
              : currency === "bnb"
              ? BnbIcon
              : TrxIcon
          }
          alt=""
        ></img>
        {currency}
      </div>
      <div className="address">{address}</div>
      <CopyToClipboard
        text={address}
        onCopy={() =>
          Toast.show({
            icon: "success",
            content: t('receive.copySuccess'),
          })
        }
      >
        <div className="copy">
          <img alt="" src={CopyIcon}></img>
          {t('receive.copyAddress')}
        </div>
      </CopyToClipboard>
    </div>
  );
}
export default Receive;
