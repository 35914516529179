import BigNumber from "bignumber.js";
import Web3 from "web3";
import { erc20 } from './abi'
const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/c9069e41f4ca4830bc7229c376099150"));

const tokenContract = new web3.eth.Contract(erc20, '0xdAC17F958D2ee523a2206206994597C13D831ec7');

export async function EthUSDTBalance(address: string) {
  let balance = '0.00'
  let erc20Balance = await tokenContract.methods.balanceOf(address).call();
  if (erc20Balance) {
    balance = new BigNumber(erc20Balance).times(0.000001).toFixed(2, 2)
  }
  return balance
}
