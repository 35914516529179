import { btcTx } from "./BtcTx"
import { ethTx } from "./EthTx"
import { bnbTx } from "./BnbTx"
import { trxTx } from "./TrxTx"
import { decryptByDES } from "./Des";
import { LocalWalletModel } from "../Data/Wallet";

export async function transfer(currency: string, address: string, amount: string) {
  let walletObj = null;
  const walletStr = localStorage.getItem("wallet");
  if (walletStr) {
    const walletArr: Array<LocalWalletModel> = JSON.parse(walletStr);
    const wallet = walletArr.find((el) => el.isDefault === 1);
    if (wallet) {
      const walletDes: string = decryptByDES(wallet.wallet, sessionStorage.getItem("password") || '');
      if (walletDes) {
        walletObj = JSON.parse(walletDes);
      }
    }
  }
  if (currency === "BTC") {
    if (walletObj) {
      return await btcTx(walletObj.btcPrivateKey, address, amount);
    }
  } else if (currency === "ETH") {
    if (walletObj) {
      return await ethTx(walletObj.ethPrivateKey, address, amount, walletObj.ethAddress);
    }
  } else if (currency === "BNB") {
    if (walletObj) {
      return await bnbTx(walletObj.bnbPrivateKey, address, amount);
    }
  } else if (currency === "TRX") {
    if (walletObj) {
      return await trxTx(walletObj.trxPrivateKey, address, amount, walletObj.trxAddress);
    }
  }
}
