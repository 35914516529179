import NanoSImg from "../../assets/images/svg/nano-s.svg";
import NanoXImg from "../../assets/images/svg/nano-x.svg";
import BlueImg from "../../assets/images/svg/blue.svg";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function SelectDevice() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  return (
    <div className="select-device">
      <div className="title">{t('selectDevice.title')}</div>
      <div className="device" onClick={() => { navigate("/set-device")} }>
        <span>Nano S</span>
        <img src={NanoSImg} alt=""></img>
      </div>
      <div className="device" onClick={() => { navigate("/set-device")} }>
        <span>Nano X</span>
        <img src={NanoXImg} alt=""></img>
      </div>
      <div className="device" onClick={() => { navigate("/set-device")} }>
        <span>Blue</span>
        <img src={BlueImg} alt=""></img>
      </div>
    </div>
  );
}
export default SelectDevice;
