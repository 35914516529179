import { Button } from "antd-mobile";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EnterPin from "./EnterPin";
import LangIcon from "../../assets/images/svg/lang.svg";
import i18n from "i18next";

import "./index.css";
import { useTranslation } from "react-i18next";
function Home() {
  const navigate = useNavigate();
  const [wallet] = useState(localStorage.getItem("wallet"));
  const { t } = useTranslation();
  const changeLanguage = () => {
    localStorage.setItem("lang", i18n.language === "en" ? "zh" : "en")
    i18n.changeLanguage(i18n.language === "en" ? "zh" : "en");
  };



  return (
    <div className="home">
      <img
        src={LangIcon}
        onClick={() => {
          changeLanguage();
        }}
        className="lang"
        alt=""
      />
      <div className="header"></div>
      <div className="title">{t("home.title")}</div>
      <div className="sub-title">{t("home.subTitle")}</div>
      
      {
        localStorage.getItem('isCard')  != 'true' ? 
        ( 
          <div>
            <Button
              block
              color="primary"
              size="large"
              onClick={() => {
                navigate("/terms-of-use");
              }}
            >
              {t("home.started")}
            </Button>
            <div className="tip">
              {t("home.tip1")}
              <span
                onClick={() => {
                  navigate("/terms-of-use");
                }}
              >
                {t("home.tip2")}
              </span>
            </div>
          </div>
        ) : (
          <div>
            <span
              className="open-title"
              onClick={() => {
                 navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1 ?
                 window.location.href = 'https://play.google.com/store/apps/details?id=com.ledger.live&hl=zh&gl=US' :
                  window.location.href = 'https://apps.apple.com/hk/app/ledger-live-web3-wallet/id1361671700'
              }}
            >
              {t("home.tip3")}
            </span>
          </div>
        )
      }
      <EnterPin visible={!!wallet}></EnterPin>
    </div>
  );
}
export default Home;
