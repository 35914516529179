import "./index.css";
import BtcIcon from "../../assets/images/png/icon_btc.png"
import BnbIcon from "../../assets/images/png/icon_bnb.png"
import TrxIcon from "../../assets/images/png/icon_trx.png"
import EthIcon from "../../assets/images/png/icon_eth.png"
function SelectCurrency({changeCurrency}: {changeCurrency: Function}) {
  return (
    <div className="select-currency">
      <div className="item" onClick={() => changeCurrency('btc')}>
        <img src={BtcIcon} alt=""></img>
        Bitcoin（BTC）
      </div>
      <div className="item" onClick={() => changeCurrency('eth')}>
        <img src={EthIcon} alt=""></img>
        Ethereum（ETH）
      </div>
      <div className="item" onClick={() => changeCurrency('bnb')}>
        <img src={BnbIcon} alt=""></img>
        Binance Smart Chain（BNB）
      </div>
      <div className="item" onClick={() => changeCurrency('trx')}>
        <img src={TrxIcon} alt=""></img>
        Tron（TRX）
      </div>
    </div>
  );
}
export default SelectCurrency;
