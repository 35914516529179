import "./index.css";
import BackIcon from '../../assets/images/svg/back.svg'
import { useNavigate } from "react-router-dom";

function Header({title}:{title: string}) {
  const navigate = useNavigate()
  return (
    <div className="header">
      <img src={BackIcon} alt="" onClick={() => {navigate('../')}}></img>
      {title}
      <span></span>
    </div>
  );
}
export default Header;
